import { ACCESS_TOKEN, ID_TOKEN, EXPIRES_AT, USER_INFO, SHOW_ROTATION_WARNING, FIREBASE_TOKEN, ANALYTICS_FILTER, AMPLIFY, } from '@fuse/messages/dist/common';
var storageType = localStorage || window.localStorage;
export function SetSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
}
export function GetSessionStorage(key) {
    return sessionStorage.getItem(key);
}
export function SetStorageComponent(key, value) {
    storageType.setItem(key, value);
}
export function GetStorageComponent(key) {
    return storageType.getItem(key);
}
export function DeleteStorageComponent(key) {
    return storageType.removeItem(key);
}
export function SetJsonStorageComponent(key, value) {
    storageType.setItem(key, JSON.stringify(value));
}
export function clearStorageComponent() {
    storageType.clear();
}
export function getUserStorageComponent(key) {
    var userInfo = storageType.getItem(key);
    if (userInfo !== null) {
        var item = JSON.parse(userInfo);
        if (!item['coursesEnrolled']) {
            item['coursesEnrolled'] = [];
        }
        return item;
    }
    return null;
}
export function setCognitoSession(cognitoSession) {
    var signInUserSession = cognitoSession.signInUserSession;
    var idToken = signInUserSession.idToken, accessToken = signInUserSession.accessToken;
    var payload = idToken.payload;
    localStorage.setItem(ACCESS_TOKEN, accessToken.jwtToken);
    localStorage.setItem(ID_TOKEN, idToken.jwtToken);
    localStorage.setItem(EXPIRES_AT, "" + payload.exp);
}
export function removeCognitoSession() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(USER_INFO);
    localStorage.removeItem(SHOW_ROTATION_WARNING);
    localStorage.removeItem(FIREBASE_TOKEN);
    localStorage.removeItem(ANALYTICS_FILTER);
    localStorage.removeItem(AMPLIFY);
    localStorage.clear();
}
export function removeCookies() {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf('=');
        var name_1 = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name_1 + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
}
export function clearAllStorage() {
    localStorage.clear();
    sessionStorage.clear();
    removeCookies();
}
export var getUserSession = function () {
    var userInfo = JSON.parse(GetStorageComponent(USER_INFO) || '{}');
    if (Object.keys(userInfo).length === 0) {
        return {
            id: '',
            auth0Id: [],
            email: '',
            name: '',
            authorities: [],
            firstLogin: false,
            applied: false,
            lastLoginDate: 0,
            image: '',
            joinDate: 0,
            modifiedDate: 0,
            organizationId: '',
            organizationMapList: [],
        };
    }
    return userInfo;
};
