import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
var sizePerPageList = [
    {
        text: '10',
        value: 10,
    },
    {
        text: '25',
        value: 25,
    },
    {
        text: '50',
        value: 50,
    },
    {
        text: '100',
        value: 100,
    },
    {
        text: '200',
        value: 200,
    },
    {
        text: '500',
        value: 500,
    },
];
var customTotal = function (from, to, size) { return (React.createElement("span", { className: "react-bootstrap-table-pagination-total ml-2" },
    "Showing ",
    from,
    " to ",
    to,
    " of ",
    size,
    " Results")); };
export var FReactTable = function (props) {
    var tableHeader = props.tableHeader, data = props.data, loading = props.loading, totalSize = props.totalSize, currentPage = props.currentPage, pageSize = props.pageSize, className = props.className, checkEnable = props.checkEnable, pagination = props.pagination, noDataIndication = props.noDataIndication, keyField = props.keyField, rowClasses = props.rowClasses, hidePagination = props.hidePagination;
    var options = {
        page: currentPage,
        sizePerPage: pageSize,
        totalSize: totalSize ? totalSize : 0,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: '',
        prePageText: 'Prev page',
        nextPageText: 'Next page',
        lastPageText: '',
        nextPageTitle: 'Next page',
        prePageTitle: 'Prev page',
        firstPageTitle: 'First page',
        lastPageTitle: 'Last page',
        showTotal: data && data.length > 0 && props.onSizePerPageChange && !loading ? true : false,
        withFirstAndLast: true,
        hideSizePerPage: data && data.length > 0 && props.onSizePerPageChange ? false : true,
        hidePageListOnlyOnePage: !pagination,
        paginationTotalRenderer: customTotal,
        onPageChange: function (page, sizePerPage) {
            if (props.onPageChange && !loading) {
                props.onPageChange(page, sizePerPage);
            }
        },
        onSizePerPageChange: function (sizePerPage, page) {
            if (props.onSizePerPageChange && !loading) {
                props.onSizePerPageChange(1, sizePerPage);
            }
        },
        sizePerPageList: sizePerPageList,
    };
    var selectRowProp = {
        mode: 'checkbox',
        clickToSelect: false,
        hideSelectAll: false,
        /*  selected: false, TODO:- expect array of string || number but pass boolean*/
        onSelect: function (row, checked, rowIndex, e) {
            if (props.onSelect) {
                props.onSelect(row, checked, rowIndex, e);
            }
        },
        onSelectAll: function (checked, rows) {
            if (props.onSelectAll) {
                props.onSelectAll(checked, rows);
            }
        },
    };
    return (React.createElement(BootstrapTable, { className: className, responsive: "md", keyField: keyField ? keyField : 'id', 
        // key={tableKey}  /* TODO:-   Type 'boolean' is not assignable to type 'string | number | null | undefined'.  */
        data: data, remote: {
            pagination: true,
        }, rowClasses: rowClasses, selectRow: checkEnable ? selectRowProp : undefined, columns: tableHeader, bordered: false, hover: true, condensed: true, loading: loading, classes: "table-responsive", noDataIndication: function () { return (noDataIndication ? noDataIndication : 'No Data'); }, pagination: hidePagination ? undefined : paginationFactory(options), overlay: overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.6)' }), onTableChange: function (type, newState) { } }));
};
