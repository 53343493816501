import Noty from 'noty';
export var showToast = function (message, id) {
    return new Noty({
        id: id || false,
        type: 'info',
        timeout: 5000,
        theme: 'bootstrap-v4',
        layout: 'topCenter',
        text: message,
        progressBar: false,
        closeWith: ['button'],
    }).show();
};
export var showSuccess = function (message) {
    return new Noty({
        type: 'success',
        timeout: 5000,
        theme: 'bootstrap-v4',
        layout: 'topCenter',
        text: message,
        progressBar: false,
        closeWith: ['button'],
    }).show();
};
export var showError = function (message, duplicate) {
    if (duplicate === void 0) { duplicate = true; }
    if (message === 'Unauthorized') {
        message = 'You are logged out. Please re-login';
    }
    var notyfy = new Noty({
        type: 'error',
        timeout: 5000,
        theme: 'bootstrap-v4',
        layout: 'topCenter',
        text: message,
        progressBar: false,
        closeWith: ['button'],
    });
    if (duplicate || (!duplicate && !document.getElementById('noty_layout__topCenter'))) {
        notyfy.show();
    } /*  else if (!duplicate && !document.getElementById('noty_layout__topCenter')) {
      notyfy.show(); TODO:Sonar This branch's code block is the same as the block for the branch on line 26.
    } */
    return notyfy;
};
